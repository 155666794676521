<template>
  <main class="container mx-auto text-center mt-12 pb-2">
    <p>{{ globalValues.footer.address }} | {{ globalValues.footer.zip_city }} | <a :href="'tel:' + globalValues.footer.phone">{{ globalValues.footer.phone }}</a> | <a :href="'mailto:' + globalValues.footer.email">{{ globalValues.footer.email }}</a> | <a :href="globalValues.footer.privacy_link">{{ globalValues.footer.privacy_link_text }}</a> | <a href="/algemenevoorwaarden.pdf" target="_blank">Algemene voorwaarden</a></p>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['globalValues'])
}
</script>
