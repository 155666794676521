import Api from '@/services/Wordpress/Api'

// Get specific page
export async  function getPage(params) {
    let data = {}

    await Api.get(`/page${params.slug}`)
        .then((response) => {
            data = {
                title: response.data.title,
                default_content: response.data.content,
                custom_content: response.data.acf,
                seo: response.data.yoast
            }
        })
        .catch((error) => {
            data = `${error.response.status} - ${error.response.statusText} (${error.message})`
        })

    return data
}

// Get all posts by slug
export async function getPostsByCategorySlug (params) {
    let data = []

    await Api.get(`/posts?category_name=${params.name}&orderby=menu_order&order=asc&per_page=99999999`)
        .then((response) => {
            response.data.map((blogPost) => {
                data.push({
                    title: blogPost.title,
                    slug: blogPost.slug,
                    custom_content: blogPost.acf,
                    seo: blogPost.yoast
                })
            })
        })
        .catch((error) => {
            data = `${error.response.status} - ${error.response.statusText} (${error.message})`
        })

    return data
}

// Get all posts by id
export async function getPostsByCategoryId (params) {
    let data = []

    await Api.get(`/posts?category=${params.id}&orderby=menu_order&order=asc&per_page=99999999`)
        .then((response) => {
            response.data.map((blogPost) => {
                data.push({
                    title: blogPost.title,
                    slug: blogPost.slug,
                    custom_content: blogPost.acf,
                    seo: blogPost.yoast
                })
            })
        })
        .catch((error) => {
            data = `${error.response.status} - ${error.response.statusText} (${error.message})`
        })

    return data
}

// Get specific post by slug
export async function getPostBySlug (params) {
    let data = {}

    await Api.get(`post/${params.slug}`)
        .then((response) => {
            data = {
                title: response.data.title,
                default_content: response.data.content,
                custom_content: response.data.acf,
                seo: response.data.yoast
            }
        })
        .catch((error) => {
            data = `${error.response.status} - ${error.response.statusText} (${error.message})`
        })

    return data
}

// Get specific post by id
export async function getPostById (params) {
    let data = {}

    await Api.get(`post/${params.id}`)
        .then((response) => {
            data = {
                title: response.data.title,
                default_content: response.data.content,
                custom_content: response.data.acf,
                seo: response.data.yoast
            }
        })
        .catch((error) => {
            data = `${error.response.status} - ${error.response.statusText} (${error.message})`
        })

    return data
}

// Get specific page by id
export async function getPageById (params) {
    let data = {}

    await Api.get(`page/${params.id}`)
        .then((response) => {
            data = {
                title: response.data.title,
                default_content: response.data.content,
                custom_content: response.data.acf,
                seo: response.data.yoast
            }
        })
        .catch((error) => {
            data = `${error.response.status} - ${error.response.statusText} (${error.message})`
        })

    return data
}
