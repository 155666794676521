<template>
  <div id="app">
    <app-header/>

    <router-view />

    <app-footer/>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  components: {
    AppHeader,
    AppFooter
  },

  created() {
    this.$store.dispatch('getGlobalValues')
    this.$store.dispatch('getBlogArticles')
    this.$store.dispatch('getCases')
  }
}
</script>