<template>
  <div class="flex flex-wrap sm:flex-row">
    <div
        v-if="data[0]"
        class="w-full sm:w-2/4 ratio2_1 border-2 border-white relative bg-cover bg-center bg-no-repeat"
        :style="`background-image: url('${data[0].custom_content.header_image.featured_image}');`"
    >
      <a :href="`${data[0].type === 'case-post' ? '/case/' : '/'}${data[0].slug}`" class="absolute inset-0"></a>
    </div>
    <div
        v-if="data[1]"
        class="w-1/2 sm:w-1/4 ratio1_1 border-2 border-white relative bg-cover bg-center bg-no-repeat"
        :style="`background-image: url('${data[1].custom_content.header_image.featured_image}');`"
    >
      <a :href="`${data[1].type === 'case-post' ? '/case/' : '/'}${data[1].slug}`" class="absolute inset-0"></a>
    </div>
    <div
        v-if="data[2]"
        class="w-1/2 sm:w-1/4 ratio1_1 border-2 border-white relative bg-cover bg-center bg-no-repeat"
        :style="`background-image: url('${data[2].custom_content.header_image.featured_image}');`"
    >
      <a :href="`${data[2].type === 'case-post' ? '/case/' : '/'}${data[2].slug}`" class="absolute inset-0"></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: false
    }
  }
}
</script>