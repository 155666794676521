import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { gsap } from "gsap"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { Draggable } from "gsap/Draggable"
import { EaselPlugin } from "gsap/EaselPlugin"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import { PixiPlugin } from "gsap/PixiPlugin"
import { TextPlugin } from "gsap/TextPlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import VueMeta from 'vue-meta'

import '@/assets/css/app.scss'

gsap.registerPlugin(CSSRulePlugin, Draggable, EaselPlugin, MotionPathPlugin, PixiPlugin, TextPlugin, ScrollToPlugin, ScrollTrigger);

window['gsap'] = gsap;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
