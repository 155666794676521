import Vue from 'vue'
import Vuex from 'vuex'
import {getPostById, getPostsByCategorySlug} from '@/services/Wordpress/Get'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalValues: {
      social_links: {},
      footer: {}
    },
    blog: {},
    cases: {}
  },
  getters: {
    globalValues(state) {
      return state.globalValues
    },
    blog(state) {
      return state.blog
    },
    cases(state) {
      return state.cases
    }
  },
  mutations: {
    SET_GLOBAL_VALUES(state, data) {
      state.globalValues = data.custom_content
    },
    SET_BLOG_ARTICLES(state, data) {
      state.blog = data
    },
    SET_CASES(state, data) {
      state.cases = data
    }
  },
  actions: {
    async getGlobalValues(context) {
      context.commit('SET_GLOBAL_VALUES', await getPostById({ id: '75' }))
    },
    async getBlogArticles(context) {
      context.commit('SET_BLOG_ARTICLES', await getPostsByCategorySlug({ name: 'blog' }))
    },
    async getCases(context) {
      context.commit('SET_CASES', await getPostsByCategorySlug({ name: 'case' }))
    }
  }
})
