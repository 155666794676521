<template>
  <main class="h-auto container mx-auto px-4 main">
    <div class="flex flex-wrap items-end py-6 sm:py-16">
      <div class="w-full sm:w-2/3">
        <h1 class="font-bold mb-4 text-2xl">
          <span v-html="pageData.title"></span>
        </h1>
        <p class="w-full sm:w-3/4">
          <span v-html="pageData.default_content"></span>
        </p>
      </div>
      <div class="w-full sm:w-1/3 text-right mt-8 sm:mt-0">
        <a :href="globalValues.social_links.linkedin" target="_blank">
          <i class="fab fa-linkedin text-3xl mr-4 cursor-pointer"></i>
        </a>
        <a :href="globalValues.social_links.facebook" target="_blank">
          <i class="fab fa-facebook-square text-3xl mr-4 cursor-pointer"></i>
        </a>
        <a :href="globalValues.social_links.instagram" target="_blank">
          <i class="fab fa-instagram-square text-3xl cursor-pointer"></i>
        </a>
      </div>
    </div>
    <div class="cases">
      <div v-for="row in caseRows">
        <first-case-row :data="row.data" v-if="row.number === 1" />
        <second-case-row  :data="row.data" v-if="row.number === 2" />
        <third-case-row :data="row.data" v-if="row.number === 3" />
        <fourth-case-row :data="row.data" v-if="row.number === 4" />
        <fifth-case-row :data="row.data" v-if="row.number === 5" />
      </div>
    </div>
  </main>
</template>

<script>
import firstCaseRow from '@/components/case/firstCaseRow'
import secondCaseRow from '@/components/case/secondCaseRow'
import thirdCaseRow from '@/components/case/thirdCaseRow'
import fourthCaseRow from '@/components/case/fourthCaseRow'
import fifthCaseRow from '@/components/case/fifthCaseRow'
import {getPageById, getPostsByCategorySlug} from '@/services/Wordpress/Get'
import { mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: 'Wij zijn een Tikkl anders | Tikkl | Een grafisch bureau dat kietelt',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'keywords',
        content: 'TIKKL, Uden'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: 'Wij zijn trots op onze doordachte huisstijlen en verrassende concepten - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      {
        property: 'og:title',
        content: 'Wij zijn een Tikkl anders | Tikkl | Een grafisch bureau dat kietelt'
      },
      {
        property: 'og:description',
        content: 'Wij zijn trots op onze doordachte huisstijlen en verrassende concepten - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      { property: 'og:url', content: 'https://tikkl.nl' }
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }
    ],
    htmlAttrs: {
      lang: 'nl',
      amp: true
    }
  },
  data() {
    return {
      caseRows: [],
      casePosts: [],
      customPosts: [],
      pageData: {
        title: '',
        default_content: '',
        custom_content: {},
        seo: {}
      }
    }
  },
  components: {
    firstCaseRow,
    secondCaseRow,
    thirdCaseRow,
    fourthCaseRow,
    fifthCaseRow
  },
  computed: mapGetters(['globalValues']),
  async created () {
    const [pageData, casePosts, customPosts] = await Promise.all([
      getPageById({ id: 157 }),
      getPostsByCategorySlug({ name: 'case' }),
      getPostsByCategorySlug({ name: 'homepage-menu-blocks' })
    ])

    casePosts.map((casePost) => {
      casePost.type = 'case-post'
    })

    customPosts.map((customPost) => {
      customPost.type = 'custom-post'
    })

    this.casePosts = casePosts
    this.customPosts = customPosts
    this.pageData = pageData

    this.calculateRows()
  },
  methods: {
    calculateRows() {
      let pushObjectNumber = 0, pushObject = false

      const smallBlock = [0, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0, 1, 0, 1]
      let blockCounter = 0

      let amountAllowedInRow = [4, 3, 3, 4, 3]
      let whichRow = 0, amountInRow = 0, createNewRow = true, rowCounter = 0

      for (let i = 0; i < this.casePosts.length; i++) {

        // Create new row
        if (createNewRow) {
          this.caseRows.push({
            number: (whichRow + 1), data: []
          })
          createNewRow = false

          // Allow a custom block to be pushed in the array
          pushObject = true
        }

        // Check if there's a custom small block allowed in the array by checking if it's a small block and a random 1 or 2 check.
        if (smallBlock[blockCounter] === 1 && (pushObject && Math.floor(Math.random() * 2) === 1)) {
          this.caseRows[rowCounter].data.push(this.customPosts[pushObjectNumber])

          // Disallow a custom block to be pushed in the array
          pushObject = false

          amountInRow++
          pushObjectNumber++
          blockCounter++

          // Check the amount of cases in a row, if max cases is reached - allow row creator to create new row
          if (amountInRow === amountAllowedInRow[whichRow]) {
            createNewRow = true
            amountInRow = 0

            whichRow++
            rowCounter++

            // Make sure the row creator recreates the first row
            if (whichRow === 5) {
              whichRow = 0
            }
          }

          // Make sure the custom blocks get reset
          if (pushObjectNumber === 4) {
            pushObjectNumber = 0
          }

          // Reset block counter
          if (blockCounter === 17) {
            blockCounter = 0
          }
        }

        // Push regular case post from Wordpress
        this.caseRows[rowCounter].data.push(this.casePosts[i])
        blockCounter++
        amountInRow++

        // Check the amount of cases in a row, if max cases is reached - allow row creator to create new row
        if (amountInRow === amountAllowedInRow[whichRow]) {
          createNewRow = true
          amountInRow = 0

          whichRow++
          rowCounter++

          // Make sure the row creator recreates the first row
          if (whichRow === 5) {
            whichRow = 0
          }
        }

        // Reset block counter
        if (blockCounter === 17) {
          blockCounter = 0
        }
      }
    }
  }
}
</script>