<template>
  <header class="flex flex-row container mx-auto justify-between pt-8 sm:pt-12 px-4 items-center">
    <div class="logo">
      <img onclick="location.href='/'" src="@/assets/images/logo_tikkl.svg" class="cursor-pointer w-2/3 sm:w-4/6 h-auto"/>
    </div>
    <div class="menu">
      <i @click="visible = true;" class="fas fa-bars text-3xl cursor-pointer"></i>
      <div class="menu__opened fixed z-10 inset-0 bg-white px-4">
        <div class="flex flex-row container mx-auto justify-between pt-8 sm:pt-12 px-0 sm:px-4 items-center">
          <div class="logo">
            <img onclick="location.href='/'" src="@/assets/images/logo_tikkl.svg" class="cursor-pointer w-2/3 sm:w-4/6 h-auto"/>
          </div>
          <div class="menu">
            <a class="flex" @click="visible = false;">
              <span class="hidden float-left mr-4 mt-1 sm:inline-block cursor-pointer">SLUITEN</span>
              <i class="fas fa-times text-3xl inline-block float-right cursor-pointer"></i>
            </a>
          </div>
        </div>
        <div class="flex flex-col container mx-auto px-4 items-center navlist-wrapper">
          <div class="navlist h-full flex items-center text-3xl justify-between">
            <ul>
              <li class="sm:float-left mr-12"><a href="/">cases</a></li>
              <li class="sm:float-left mr-12"><a href="/de-logos">logo's</a></li>
              <li class="sm:float-left mr-12"><a href="/de-blog">blog</a></li>
              <li class="sm:float-left mr-12"><a href="/de-mensen">team</a></li>
              <li class="sm:float-left"><a href="/contact">contact</a></li>
            </ul>
          </div>
          <div class="flex flex-row container mx-auto justify-between items-center">
            <div class="search">

            </div>
            <div class="social">
              <a href="https://nl.linkedin.com/company/tikkl-grafisch-bureau" target="_blank">
                <i class="fab fa-linkedin text-3xl mr-4 cursor-pointer"></i>
              </a>
              <a href="https://www.facebook.com/Tikklyou/" target="_blank">
                <i class="fab fa-facebook-square text-3xl mr-4 cursor-pointer"></i>
              </a>
              <a href="https://www.instagram.com/tikklyou/" target="_blank">
                <i class="fab fa-instagram-square text-3xl cursor-pointer"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      bars: null,
      times: null,
      menuOpened: null,
    };
  },

  watch: {
    visible: function (visible) {

      // Menu becomes visible
      if (visible) {

        document.body.classList.add('overflow-y-hidden');

        gsap.to(this.bars, {
          duration: 0.3,
          autoAlpha: 0,
          ease: 'back',
          onUpdate: () => {
            gsap.to(this.times, {
              duration: 0.3,
              autoAlpha: 1,
              ease: 'back'
            });

            gsap.to('.menu__opened', {
              duration: 0.3,
              autoAlpha: 1,
              ease: 'back'
            });


          }
        });

        return;
      }

      // Menu becomes invisble
      document.body.classList.remove('overflow-y-hidden');

      gsap.to(this.times, {
        duration: 0.3,
        autoAlpha: 0,
        ease: 'back',
        onUpdate: () => {
          gsap.to(this.bars, {
            duration: 0.3,
            autoAlpha: 1,
            ease: 'back',
          });

          gsap.to(this.menuOpened, {
            duration: 0.3,
            autoAlpha: 0,
            ease: 'slow'
          });
        }
      });
    },
  },

  mounted() {

    this.bars = document.querySelector('.fa-bars');
    this.times = document.querySelector('.fa-times');
    this.menuOpened = document.querySelector('.menu__opened');

    gsap.to(this.times, {
      duration: 0,
      autoAlpha: 0,
      ease: 'slow'
    });

    gsap.to(this.menuOpened, {
      duration: 0,
      autoAlpha: 0,
      ease: 'slow'
    });
  },
}
</script>